import VersionApi from '@/services/api/Version'

const _ = require('lodash')

const state = () => ({
  latestVersion: null,
  installingNewVersion: false,
})

const mutations = {
  setLatestVersion(state, latestVersion) {
    state.latestVersion = latestVersion
  },
  setInstallingNewVersion(state, newVal) {
    state.installingNewVersion = newVal
  },
}

const getters = {
  newVersionAvailable(state) {
    if (!state.latestVersion) return false

    console.log('newVersionAvailable', process.env.VUE_APP_VERSION)
    return _.lt(process.env.VUE_APP_VERSION, state.latestVersion)
  },
  installingNewVersion: (state) => state.installingNewVersion,
}

const actions = {
  async fetchLatestVersion({ commit }) {
    const { uiVersion } = await VersionApi.getVersionData()
    commit('setLatestVersion', uiVersion)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
