import OrgPhoneApi from '@/services/api/OrgPhone'

// initial state
const state = () => ({
  status: '',
})

// getters
const getters = {
  loading: (state) => state.status === 'loading',
}

// actions
const actions = {
  fetchOrgPhones({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('request')
      OrgPhoneApi.fetchOrgPhones(payload)
        .then((resp) => {
          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          commit('error')
          console.log(err)
          reject(err)
        })
    })
  },

  assignPhoneNumber({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('request')
      OrgPhoneApi.assignPhoneNumbers(payload)
        .then((resp) => {
          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          commit('error')
          console.log(err)
          reject(err)
        })
    })
  },

  swapPhoneNumber({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('request')
      OrgPhoneApi.swapPhoneNumber(payload)
        .then((resp) => {
          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          commit('error')
          console.log(err)
          reject(err)
        })
    })
  },
}

// mutations
const mutations = {
  request(state) {
    state.status = 'loading'
  },

  success(state) {
    state.status = 'success'
  },

  error(state) {
    state.status = 'error'
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
