import { watch } from 'vue'
import { EventBus } from '@/services/event-bus'
import store from '@/store'
import _debounce from 'lodash/debounce'
import _isEqual from 'lodash/isEqual'
import _cloneDeep from 'lodash/cloneDeep'

// State for tracking modifications
const stateLastModified = {}

// Singleton broadcast channel
let dialerChannel = null

// Keys to watch for state synchronization
const commonKeysToWatch = [
  'callCompleted',
  'callDisposition',
  'callDispositionGroup',
  'callOutcome',
  'currentPhoneRecord',
  'feedbackIssues',
  'feedbackNote',
  'feedbackRating',
  'feedbackReportedSpam',
  'forceRecordOption',
  'hasMissedCall',
  'hasUnreadTextMessage',
  'hungUpNumberInMpc',
  'isMuted',
  'isRecording',
  'lastSelectedTab',
  'note',
  'onPhone',
  'postCallActionLoader',
  'preCallActionLoader',
  'redialNumberInMpc',
  'secondParticipant',
  'secondParticipantConnected',
  'secondParticipantRinging',
  'selectedMessageThread',
  'selectedMicrophone',
  'selectedOutboundCaller',
  'selectedOutboundCallerObj',
  'selectedSpeaker',
  'selectedTab',
  'subject',
  'transferInProgress',
  'smsInput',
  'currentSmsActionId',
  'messages',
  'smsShowTemplates',
  'smsTemplateSearchQuery',
  'smsTemplatePageLoading',
  'smsTemplates',
  'smsPageLoading',
  'sendingSms',
  'audioTestCallInProgress',
  'audioTestCallStatus',
  'audioTestResultsLoading',
  'audioTestResults',
  'incomingCalls',
  'isRinging',
  'incomingCallParams',
]

const masterKeysToWatch = [
  ...commonKeysToWatch,
  'activeCallAnswered',
  'machineDetected',
  'transferredTo',
]

const remoteKeysToWatch = [...commonKeysToWatch]

// Service to handle dialer communication between master and remote
class DialerCommunicationService {
  constructor() {
    this.state = null
    this.getters = null
    this.actions = null
    this.debouncedSync = _debounce((to) => this.syncState(to), 200)
  }

  init(state, getters, actions) {
    this.state = state
    this.getters = getters
    this.actions = actions
    return this
  }

  getDialerChannel() {
    if (!dialerChannel) {
      dialerChannel = new BroadcastChannel('symbo-dialer-channel')
    }
    return dialerChannel
  }

  enableRemoteDialerCommunication() {
    if (!this.state.isRemote) {
      // Master listens for commands from remote
      this.setupMasterListener()
    } else {
      // Remote listens for state updates
      this.setupRemoteListener()
    }
    this.setupStateChangeWatchers()
  }

  // Send command from one instance to another
  sendCommand(type, to = 'master', payload = {}) {
    if (!type) throw new Error('📞 No type provided')
    if (!to) throw new Error('📞 No destination provided')
    if (
      (this.state.isRemote && to === 'remote') ||
      (this.state.isMaster && to === 'master')
    ) {
      console.warn('❌ Can not send command to self')
      return
    }
    console.log(`📞 Sending command to ${to}`, type, new Date())
    this.getDialerChannel().postMessage({
      type,
      payload,
      source: 'symbo-dialer',
      to,
    })
  }

  // Sync state between instances
  syncState(to = 'remote') {
    console.log(
      `🔃 Sync state to ${to}`,
      `Device registered:`,
      this.state?.device?.isRegistered()
    )
    if (
      (this.state.isRemote && to === 'remote') ||
      (this.state.isMaster && to === 'master')
    ) {
      console.warn(`❌ Can not sync self`)
      console.warn('state.isRemote', this.state.isRemote)
      console.warn('state.isMaster', this.state.isMaster)
      return
    }

    const keys = to === 'remote' ? masterKeysToWatch : remoteKeysToWatch
    const stateToSync = keys.reduce(
      (acc, key) => {
        if (key in this.state) {
          acc[key] = this.state[key]
        }
        return acc
      },
      {
        stateLastModified,
      }
    )

    // Broadcast to all listeners
    this.getDialerChannel().postMessage({
      type:
        to == 'remote'
          ? 'dialer:master-state-update'
          : 'dialer:remote-state-update',
      payload: stateToSync,
      source: 'symbo-dialer',
      to,
    })
  }

  // Set up watchers for state changes that should be synced
  setupStateChangeWatchers() {
    if (!this.state.isRemote && !this.state.isMaster) return
    const type = this.state.isRemote ? 'remote' : 'master'
    const to = this.state.isRemote ? 'master' : 'remote'
    const keysToWatch = this.state.isRemote
      ? remoteKeysToWatch
      : masterKeysToWatch
    keysToWatch.forEach((key) => {
      watch(
        () => this.state[key],
        () => {
          console.log(`📞 ${type} state change:`, key, this.state[key])
          this.debouncedSync(to)
        }
      )
    })

    watch(
      () => this.getters.useLocalPresence(),
      () => this.sendCommand('refresh-user-settings', to)
    )

    // Handling this separaterly because:
    // In JS audioDevices are not copyable. They have a strong definition and cannot be copied.
    // Solution: We don't need the complete audioDevice instance for remote. We just need a few props to display.
    // So we only send those props.
    if (this.state.isMaster) {
      watch(
        () => this.state.availableDevices,
        () =>
          this.sendCommand(
            'set-available-devices',
            to,
            this.state.availableDevices.map((i) => ({
              deviceId: i.deviceId,
              groupId: i.groupId,
              kind: i.kind,
              label: i.label,
            }))
          )
      )
    }
  }

  // Apply state changes from remote or master
  applyState(newState, to) {
    if (
      (this.state.isRemote && to !== 'remote') ||
      (this.state.isMaster && to !== 'master')
    ) {
      console.warn('❌ Must apply state to self')
      console.warn('state.isRemote', this.state.isRemote)
      console.warn('state.isMaster', this.state.isMaster)
      return
    }

    const externalStateLastModified = newState.stateLastModified
    let stateChangeDetected = false
    try {
      // Update local state without triggering actions
      Object.keys(newState)
        .filter((i) => i != 'stateLastModified')
        .forEach((key) => {
          // Check if the value is outdated
          if (
            (externalStateLastModified[key] || 0) <
            (stateLastModified[key] || 0)
          ) {
            // console.warn(`❌ ${key} is outdated`)
            return
          }
          if (_isEqual(this.state[key], newState[key])) return
          if (key in this.state) {
            if (key === 'selectedMicrophone') {
              this.actions.setMicrophoneDevice(newState[key])
              stateChangeDetected = true
            } else if (key === 'selectedSpeaker') {
              this.actions.setSpeakerDevice(newState[key])
              stateChangeDetected = true
            } else if (key === 'activeCallAnswered') {
              this.state[key] = newState[key]
              this.actions.startCallDuration()
              stateChangeDetected = true
            } else if (typeof newState[key] === 'object') {
              this.state[key] = _cloneDeep(newState[key])
              stateChangeDetected = true
            } else {
              this.state[key] = newState[key]
              stateChangeDetected = true
            }
          }
        })
    } finally {
      if (!stateChangeDetected) {
        // console.warn('❌ No state change detected')
      } else {
        console.log('📞 State applied successfully')
      }
    }
  }

  // Master listens for commands from remote
  setupMasterListener() {
    this.getDialerChannel().onmessage = (event) => {
      const { data } = event
      if (!data || data.source !== 'symbo-dialer') return

      switch (data.type) {
        case 'dialer:remote-state-update':
          // Handle state updates from remote
          this.applyState(data.payload, 'master')
          break
        case 'dialer:remote-connected':
          // Remote dialer connected, broadcast initial state
          this.syncState('remote')
          break
        case 'dialer:toggle-call':
          this.actions.toggleCall()
          break
        case 'dialer:toggle-mute':
          this.actions.toggleMute()
          break
        case 'dialer:drop-voicemail':
          this.actions.dropVoicemail(data.payload?.id)
          break
        case 'dialer:update-call':
          this.actions.updateCall(data.payload?.saveNoteOnly || false)
          break
        case 'refresh-user-settings':
          store.dispatch('user/load')
          break
        case 'dialer:send-digit':
          this.actions.sendDigit(data.payload?.digit)
          break
        case 'dialer:start-transfer':
          this.actions.startCallTransfer(data.payload?.group)
          break
        case 'dialer:complete-transfer':
          this.actions.completeCallTransfer()
          break
        case 'dialer:abort-transfer':
          this.actions.abortCallTransfer()
          break
        case 'dialer:redial-in-mpc':
          this.actions.redialInMpc(data.payload?.record)
          break
        case 'dialer:add-to-call':
          this.actions.addToCall(data.payload?.record)
          break
        case 'dialer:remove-second-participant':
          this.actions.removeSecondParticipant(data.payload?.isAlreadyHungup)
          break
        case 'dialer:abort-add-to-call':
          this.actions.abortAddToCall(data.payload?.record)
          break
        case 'dialer:merge-add-to-call':
          this.actions.mergeAddToCall()
          break
        case 'dialer:double-dial':
          this.actions.doubleDial()
          break
        case 'dialer:start-audio-test-call':
          this.actions.startAudioTestCall()
          break
        case 'dialer:stop-audio-test-call':
          this.actions.stopAudioTestCall()
          break
        case 'dialer:send-sms':
          EventBus.$emit('send-sms')
          break
        case 'dialer:sms-get-next-page':
          console.log('Received - dialer:sms-get-next-page')
          EventBus.$emit('sms-get-next-page')
          break
        case 'dialer:sms-get-templates':
          console.log('dialer:sms-get-templates')
          EventBus.$emit('sms-get-templates')
          break
        case 'dialer:sms-get-templates-next-page':
          EventBus.$emit('sms-get-templates-next-page')
          break
        case 'dialer:accept-incoming-call':
          this.actions.acceptIncomingCall()
          break
        case 'dialer:ignore-incoming-call':
          this.actions.ignoreIncomingCall()
          break
        case 'dialer:toggle-recording':
          this.actions.toggleRecording()
          break
        case 'dialer:start-noise-suppression':
          this.actions.startNoiseSuppression()
          break
        case 'dialer:stop-noise-suppression':
          this.actions.stopNoiseSuppression()
          break
      }
    }
  }

  // Remote listens for state updates from master
  setupRemoteListener() {
    this.getDialerChannel().onmessage = (event) => {
      const { data } = event
      if (!data || data.source !== 'symbo-dialer') return

      if (data.type === 'dialer:master-state-update' && data.payload) {
        this.applyState(data.payload, 'remote')
      }

      if (data.type === 'set-available-devices') {
        this.state.availableDevices = data.payload || []
      }

      if (data.type === 'refresh-user-settings') {
        store.dispatch('user/load')
      }
    }

    // Announce presence
    this.getDialerChannel().postMessage({
      type: 'dialer:remote-connected',
      source: 'symbo-dialer',
    })
  }

  // Track state changes with timestamps
  track(key, value) {
    stateLastModified[key] = Date.now()
    return value
  }

  // Close channel when service is destroyed
  destroy() {
    if (dialerChannel) {
      dialerChannel.close()
      dialerChannel = null
    }
  }
}

// Create and export a singleton instance
const dialerCommunicationService = new DialerCommunicationService()
export default dialerCommunicationService
