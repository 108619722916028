import Vue from 'vue'
import store from '@/store'
import Pusher from 'pusher-js'
import { getLSItem } from '@/utils/localStorage'
import { EventBus } from '@/services/event-bus'

const state = () => ({
  subscribed: false,
  channel: null,
})
// getters
const getters = {
  channel: (state) => state.channel,
}
// actions
const actions = {
  pusherSubscribe({ commit, dispatch }) {
    console.log('⚡️ Soketi subscribing')
    return new Promise((resolve, reject) => {
      try {
        if (!Vue.prototype.$soketi) {
          dispatch('setupSoketi')
        }
        const soketi = Vue.prototype.$soketi

        const channel = soketi.subscribe(
          `private-user-${store.getters['user/user'].id}`
        )

        soketi.connection.bind('connected', function () {
          console.log('⚡️ Soketi connected')
        })

        soketi.connection.bind('disconnected', function () {
          console.log('⚡️ Soketi disconnected')
          setTimeout(() => {
            EventBus.$emit('soketi-disconnected')
          }, 1000)
        })

        soketi.connection.bind('unavailable', function () {
          console.log('⚡️ Soketi unavailable')
        })

        soketi.connection.bind('failed', function () {
          console.log('⚡️ Soketi failed')
        })

        soketi.connection.bind('error', function (error) {
          console.error('⚡️ Soketi connection error', error)
        })

        commit('subscribe', { channel })
        resolve(channel)
      } catch (error) {
        reject(error)
      }
    })
  },

  setupSoketi() {
    const token = getLSItem('token', false)
    const stytchToken = getLSItem('stytchToken', false)
    const headers = stytchToken
      ? { 'X-Stytch-JWT': stytchToken }
      : { Authorization: `Bearer ${token}` }
    Vue.prototype.$soketi = new Pusher(process.env.VUE_APP_SOKETI_APP_KEY, {
      cluster: 'us',
      forceTLS: true,
      disableStats: true,
      enabledTransports: ['ws', 'wss'],
      wsHost: process.env.VUE_APP_SOKETI_HOST,
      wsPort: process.env.VUE_APP_SOKETI_PORT || 6001,
      authEndpoint: `${process.env.VUE_APP_SYMBO_API_URL}/authSoketi`,
      auth: {
        headers,
      },
    })
  },
  pusherUnsubscribe({ commit }) {
    commit('unsubscribe')
  },
}
const mutations = {
  subscribe(state, { channel }) {
    state.subscribed = true
    state.channel = channel
  },
  unsubscribe(state) {
    state.subscribed = false
    state.channel = null
  },
}
export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
}
