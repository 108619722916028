// -----------------------------------------------------------------------------
// This is a simple interface for when the dialer is loaded in the HubSpot CRM
// it allows us to native dial from HubSpot
// -----------------------------------------------------------------------------
// https://developers.hubspot.com/docs/api/crm/extensions/calling-sdk

import CallingExtensions from '@hubspot/calling-extensions-sdk'
import { EventBus } from '@/services/event-bus'

import {
  dialerServiceActions,
  dialerServiceMutations,
  dialerServiceGetters,
  enableRemoteDialerCommunication,
} from '@/services/stateful/dialer/DialerService'

const options = (state) => ({
  debugMode: false,
  eventHandlers: {
    onReady: ({ iframeLocation }) => {
      console.log(
        '📞 HubSpot dialer plugin ready... ',
        `iframeLocation: ${iframeLocation}`
      )
      if (iframeLocation === 'remote') {
        dialerServiceMutations.setIsRemote(true)
      } else if (iframeLocation === 'window') {
        dialerServiceMutations.setIsMaster(true)
      }

      enableRemoteDialerCommunication()

      dialerServiceMutations.setHubSpotSdkActive(true)
      dialerServiceActions.initDialerService()
      EventBus.$emit('hubspot-dialer-plugin-ready')
    },
    onDialNumber: (data) => {
      if (dialerServiceGetters.isRemote()) return
      const { phoneNumber, objectId, calleeInfo, objectType } = data
      dialerServiceActions.resetState()
      const externalId =
        objectType == 'DEAL' && calleeInfo?.calleeId
          ? calleeInfo.calleeId
          : objectId
      state.currentPhoneRecord = {
        number: phoneNumber,
        externalId,
        callImmediately: dialerServiceGetters.oneClickDialEnabled() || false,
      }
    },
    onVisibilityChanged: (data) => {
      console.log('📞 HubSpot dialer plugin visibility changed:', data)
    },
    onCallerIdMatchSucceeded: (data) => {
      console.log('📞 HubSpot dialer plugin caller ID match succeeded:', data)
    },
    onCallerIdMatchFailed: (data) => {
      console.log('📞 HubSpot dialer plugin caller ID match failed:', data)
    },
  },
})

const initWatchers = (extensions, state) => {
  EventBus.$off('call-started')
  EventBus.$on('call-started', () => {
    console.log('📞 HubSpot dialer plugin call started...')
    if (dialerServiceGetters.isRemote()) return
    const callInfo = {
      createEngagement: false,
    }
    extensions.outgoingCall(callInfo)
  })

  EventBus.$off('call-answered')
  EventBus.$on('call-answered', () => {
    console.log('📞 HubSpot dialer plugin call answered...')
    if (dialerServiceGetters.isRemote()) return
    extensions.callAnswered()
  })

  EventBus.$off('call-ended')
  EventBus.$on('call-ended', () => {
    console.log('📞 HubSpot dialer plugin call ended...')
    if (dialerServiceGetters.isRemote()) return
    extensions.callEnded()
  })

  EventBus.$off('call-action-completed')
  EventBus.$on('call-action-completed', () => {
    console.log('📞 HubSpot dialer plugin call action completed...')
    if (dialerServiceGetters.isRemote()) return
    const data = {
      hideWidget: true,
    }
    extensions.callCompleted(data)
  })

  // EventBus.$off('close-crm-dialer')
  // EventBus.$on('close-crm-dialer', () => {
  //   console.log('📞 HubSpot dialer plugin should close...')
  //   const data = {
  //     hideWidget: true,
  //   }
  //   extensions.callCompleted(data)
  //   EventBus.$emit('hubspot-dialer-plugin-ready')
  // })

  EventBus.$off('call-incoming')
  EventBus.$on('call-incoming', () => {
    console.log('📞 HubSpot dialer plugin call incoming...')
    if (dialerServiceGetters.isRemote()) return
    console.log(
      '📞 HubSpot dialer plugin incoming call:',
      state.incomingCalls[0]
    )
    extensions.incomingCall({
      toNumber: state.incomingCalls?.[0]?.['X-Ph-Called-Phone'],
      fromNumber: state.incomingCalls?.[0]?.['X-Ph-Prospect-Phone'],
      createEngagement: false,
    })
  })

  EventBus.$off('hubspot-dialer-plugin-ready')
  EventBus.$on('hubspot-dialer-plugin-ready', () => {
    if (dialerServiceGetters.isRemote()) return
    extensions.initialized({
      isLoggedIn: true,
      isAvailable: true,
      sizeInfo: {
        width: 350,
        height: 420,
      },
    })
  })

  EventBus.$off('hs-plugin-open-contact')
  EventBus.$on('hs-plugin-open-contact', (data) => {
    if (dialerServiceGetters.isRemote()) return
    extensions.navigateToRecord({ data })
  })
}

export default {
  init: (state) => {
    const extensions = new CallingExtensions(options(state))
    console.log('📞 HubSpot dialer plugin initializing...')
    initWatchers(extensions, state)
  },
}
