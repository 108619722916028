import api from './Api'

export default {
  fetchOrgPhones(options = {}) {
    let path = '/orgPhoneNumbers'
    let pathParams = []

    const { user } = options
    if (user) pathParams.push(`include=${user}`)

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.get(path)
  },

  assignPhoneNumbers(options = {}) {
    const payload = {}

    const { phoneId, userId } = options
    payload.user_id = userId

    return api.put(
      `/phoneNumbers/${phoneId}/actions/assignPhoneNumber`,
      payload
    )
  },

  swapPhoneNumber(payload) {
    return api.put(`phoneNumbers/actions/swapPhoneNumber`, payload)
  },
}
