import store from '@/store/index'

const isFeatureEnabled =
  store?.getters?.['user/isFeatureFlagEnabled'] || (() => false)

export default {
  isNextBestActionsEnabled: () =>
    isFeatureEnabled('next-best-actions') || false,
  isPlivoDriverEnabled: () => isFeatureEnabled('plivo-driver') || false,
  isDialerHistorySearchEnabled: () =>
    isFeatureEnabled('dialer-history-search') || false,
  isCallMonitoringEnabled: () => isFeatureEnabled('call-monitoring') || false,
  isAppNotificationsEnabled: () =>
    isFeatureEnabled('app-notifications') || false,
  isPowerDialingEnabled: () => isFeatureEnabled('power-dialing'),
  showPDCredits: () => isFeatureEnabled('show-pd-credits'),
  avoidDNCNumbersInImport: () =>
    isFeatureEnabled('avoid-dnc-numbers-in-contact-import') || false,
  isPinnedActionsEnabled: () => isFeatureEnabled('pinned-actions') || false,
  isZillowBtnEnabled: () => isFeatureEnabled('zillow') || false,
  isGoogleMapsBtnEnabled: () => isFeatureEnabled('gmaps-btn') || false,
  outboundTextMessagingEnabled: () =>
    isFeatureEnabled('outbound-text-messaging') || false,
  hidePdTableOnConnect: () =>
    isFeatureEnabled('hide-pd-table-on-connect') || false,
  plivoStunServersEnabled: () =>
    isFeatureEnabled('plivo-stun-servers-enabled') || false,
  csvUploadUnderMaintenance: () =>
    isFeatureEnabled('csv-upload-under-maintenance') || false,
  machineDetectionV2Enabled: () =>
    isFeatureEnabled('machine-detection-v2-enabled') || false,
  pdPrefetchContactEnabled: () =>
    isFeatureEnabled('pd-prefetch-contact-enabled') || false,
  isTelnyxVerifyEnabled: () =>
    isFeatureEnabled('telnyx-verify-enabled') || false,
  useOldNotesInPDCall: () =>
    isFeatureEnabled('use-old-notes-in-pd-call') || false,
  disableUpdateBilling: () =>
    isFeatureEnabled('disable-update-billing') || false,
  useCrmOwnerForImportDialog: () =>
    isFeatureEnabled('use-crm-owner-for-import-dialog') || false,
  friendlyOutboundConnectTone: () =>
    isFeatureEnabled('friendly-outbound-connect-tone') || false,
  askElephantEnabled: () => isFeatureEnabled('ask-elephant-enabled') || false,
  viewActionsOnlyOnDashboard: () =>
    isFeatureEnabled('view-actions-only-on-dashboard') || false,
  assignUserInSequenceEnabled: () =>
    isFeatureEnabled('assign-user-in-sequence-enabled') || false,
  callOutcomeHotKeysEnabled: () =>
    isFeatureEnabled('call-outcome-hotkeys-enabled') || false,
}
