import axios from 'axios'
import router from '@/router'
import store from '@/store'
import * as Sentry from '@sentry/vue'

import { getLSItem } from '@/utils/localStorage'
import { isEmpty } from 'lodash'

// Default config for the axios instance
const axiosParams = {
  baseURL: process.env.VUE_APP_SYMBO_API_URL,
}

// Create axios instance with default params, set auth header
const axiosInstance = axios.create(axiosParams)
const token = getLSItem('token', false)
const stytchToken = getLSItem('stytchToken', false)

if (stytchToken) setApiAuthToken(stytchToken, 'stytch')
else if (token) setApiAuthToken(token)

// Setup unauthorized interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    Sentry.setContext(
      'store',
      JSON.stringify(store.state, getCircularReplacer())
    )
    return response
  },
  function (error) {
    // Return any error which is not due to auth
    // back to the service that called it
    if (error.response?.status !== 401) {
      return Promise.reject(error)
    }

    // Missing phone verification
    const user = store.getters['user/user']

    if (!isEmpty(user) && !user.otp_verified && !user.otp_override) {
      return Promise.reject(error)
    }

    // For not email verirfied
    const errorCode = error.response?.data?.error?.code
    console.log('error code in api', errorCode)

    if (['EMIAL_NOT_VERIFIED', 'EMAIL_NOT_VERIFIED'].includes(errorCode))
      return Promise.reject(error)

    // For unauthorized requests, clear local storage
    // and return user to login screen
    store.dispatch('user/logout')
    if (router.currentRoute?.path !== '/login') router.push('login')
    return Promise.reject(error)
  }
)

// Setup API logging
if (process.env.VUE_APP_SYMBO_API_LOG === 'true') {
  import('./Logger').then(({ requestLogger, responseLogger }) => {
    axiosInstance.interceptors.request.use(requestLogger)
    axiosInstance.interceptors.response.use(responseLogger)
  })
}

const ABORT_CONTROLLERS = new Map()
const abortSafely = (key) => ABORT_CONTROLLERS.get(key)?.abort?.()
const didAbort = (error) => axios.isCancel(error)

export const withAbort =
  (fn) =>
  async (...args) => {
    const originalConfig = args[args.length - 1]
    let { abortKey, ...config } = originalConfig

    if (abortKey) {
      abortSafely(abortKey)
      const controller = new AbortController()
      ABORT_CONTROLLERS.set(abortKey, controller)
      config.signal = controller.signal
    }

    try {
      // Pass all arguments from args besides the config
      const response = await fn(...args.slice(0, args.length - 1), config)
      return response.data
    } catch (error) {
      // Add "aborted" property to the error if the request was cancelled
      didAbort(error) && (error.aborted = true)
      throw error
    }
  }

// Log API errors if env variable (VUE_APP_SYMBO_API_DEBUG) is set true
export const withLogger = async (promise) =>
  promise.catch((error) => {
    if (process.env.VUE_APP_SYMBO_API_DEBUG !== 'true') throw error

    if (error.response) {
      // The request was made and the server responded with a
      // status code that falls out of the range of 2xx
      console.log('🚀 ~ API response data', error.response.data)
      console.log('🚀 ~ API response status', error.response.status)
      console.log('🚀 ~ API response headers', error.response.headers)
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest
      // in the browser and an instance of
      // http.ClientRequest in node.js
      console.log('🚀 ~ API request', error.request)
    } else {
      // Something happened in setting up the request that triggered an error
      console.log('🚀 ~ API error message', error.message)
    }
    console.log('🚀 ~ API error config', error.config)

    throw error
  })

// Main api function
const api = (axios) => {
  return {
    get: (url, config = {}) => withLogger(withAbort(axios.get)(url, config)),
    post: (url, body, config = {}) =>
      withLogger(withAbort(axios.post)(url, body, config)),
    patch: (url, body, config = {}) =>
      withLogger(withAbort(axios.patch)(url, body, config)),
    put: (url, body, config = {}) =>
      withLogger(withAbort(axios.put)(url, body, config)),
    delete: (url, config = {}) =>
      withLogger(withAbort(axios.delete)(url, config)),
  }
}

function setApiAuthToken(token, provider) {
  if (provider === 'stytch')
    axiosInstance.defaults.headers.common['X-Stytch-JWT'] = token
  else
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

function clearApiAuthToken() {
  delete axiosInstance.defaults.headers.common['X-Stytch-JWT']
  delete axiosInstance.defaults.headers.common['Authorization']
}

function getCircularReplacer() {
  const seen = new WeakSet()
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) return
      seen.add(value)
    }
    return value
  }
}

export { setApiAuthToken, clearApiAuthToken }
export default api(axiosInstance)
